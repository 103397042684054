import { Typography, Container, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';



 const InvoicePayments = ({payments}) => {
    return (
      <>
        {payments && payments.length > 0 && (
          <Container
            sx={{
              width: {
                xs: '18rem',
                sm: '35rem',
                md: '55rem',
                lg: '75rem',
                xl: '95rem',
              },
              marginTop: '1rem',
            }}
          >
            <Typography variant='h5' sx={{ marginBottom: '2rem' }}>
              Payment History
            </Typography>

            <Table sx={{ maxWidth: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map(payment => {
                  return (
                    <TableRow key={payment.id}>
                      <TableCell>{payment.createdAt}</TableCell>
                      <TableCell>{payment.amount}</TableCell>
                      <TableCell>{payment.status}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Container>
        )
      }
      </>
    )
        
  }
  
  export default InvoicePayments;
import { createBrowserRouter, redirect } from 'react-router-dom';
import RouteObject from './RouteObject';
import invoiceListRoute from './invoiceListRoute';
import loginRoute from './loginRoute';
import paymentCompleteRoute from './paymentCompleteRoute';
import Root from '../components/Root';
import payInvoiceRoute from './payInvoiceRoute';
import logoutRoute from './logoutRoute';

export const routes : RouteObject[] = [
  {
    id: 'root',
    Component: Root,
    errorElement: "error",
    children: [
      {
        index: true,
        loader: () => redirect('/invoices'),
      },
      invoiceListRoute,
      loginRoute,
      payInvoiceRoute,
      paymentCompleteRoute,
      logoutRoute,
    ],
  },
];

const router = createBrowserRouter(routes);

export default router;





import { WidthFullRounded } from '@mui/icons-material';
import { Card, Button, CardHeader, CardContent, Typography, Paper, Box } from '@mui/material'
import { useRef } from 'react';
import { useResizeObserver } from 'usehooks-ts';

const PaymentCard = ({id, onClick, title, last4, expiration, name, buttonText, disabled}) => {

    const _onClick = onClick;

    const titleSize = title.length > 14 ? '100%' : '140%';



    return (
        <Paper key={id} elevation={2} sx={{
            width: '85%',
            height: '80%',
            padding: '4%',
            borderRadius: '10px',
            marginTop:'-5%',
            marginLeft: '-5%',
            textAlign: 'left',
            overflow:'hidden',
            position: 'sticky',
            ":hover": {
                boxShadow: 5,
                cursor: 'default'
            }
        }}> 

            <Typography
                variant='h4'
                fontSize={titleSize}
                color='primary'
                noWrap
                textTransform={'capitalize'}
                textOverflow={'ellipsis'}
                maxHeight={'200%'}
                width={'100%'}
                height={'25%'}
            >
                {title}
            </Typography>
            <Typography
                variant='h4'
                fontStyle={'italic'}
                fontSize={'120%'}
                textOverflow={'unset'}
                color='GrayText'
                noWrap
                width={'100%'}
                height={'25%'}
                textAlign={'right'}
                
            >
                {last4}
            </Typography>
            <Box
                alignItems={'end'}
                justifyContent={'space-between'}
                display={'flex'}
                width={'100%'}
                height={'50%'}
            >
                <Box
                    width={'60%'}
                    height={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'end'}
                    alignItems={'start'}
                >
                    <Typography
                        variant='h6'
                        color='secondary'
                        width={'60%'}
                        height={'60%'}
                        marginLeft={ '10%' }
                        fontSize={'90%'}
                        noWrap
                    >
                        {expiration}
                    </Typography>
                    <Typography
                        variant='h6'
                        width={'95%'}
                        height={'40%'}
                        fontSize={'75%'}
                        noWrap
                        textOverflow={'clip'}
                        textTransform={'uppercase'}
                    >
                        {name}
                    </Typography>
                </Box>
                
                <Button
                    onClick={_onClick}
                    disabled={disabled}
                    variant='contained'
                    color='primary'
                    sx={{
                        borderRadius: '7px',
                        boxShadow: 3,
                        padding: '2%',
                        overflow: 'hidden',
                        textOverflow: 'hidden',
                        fontWeight: 'bold',
                        fontSize: buttonText.length > 10 ? '50%' : '80%',
                        height: '75%',
                        width: '35%',
                    }}
                >
                    {buttonText}
                </Button>
            </Box>
            
        </Paper>
    )
};

export default PaymentCard;
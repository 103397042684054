import { useEffect } from 'react';
import { useNavigate, useSearchParams, json } from 'react-router-dom';
import RouteObject from './RouteObject';
import LoginForm from '../components/LoginForm';
import { signInWithEmailAndPassword } from 'firebase/auth';
import initFirebase from '../data/firebase';
import { useAuthContext } from '../data/context/AuthContext';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';


const loginRoute : RouteObject = {
  id: 'login',
  path: '/login',
  Component: () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {user} = useAuthContext();
    
    useEffect(() => {
      if (user) {
        navigate(searchParams?.get('from') || '/');
      }
    }, [user, navigate, searchParams]);

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: {xs: 'start', sm: 'center'},
          height: '80vh'
        }}
      >
        <Paper sx={{
            padding: 2,
            boxShadow: {xs: 'none', sm: 3},
            maxWidth: '25rem',
          }}
        >
          <LoginForm onComplete={() => navigate(searchParams?.get('from') || '/')}/>
        </Paper>
      </Box>
    )
  },
  action: async ({request}) => {
    console.log('Login Route Action')
    const data = await request.formData();
    const intent = data.get('intent');
    if (intent === 'loginEmailPassword') {
      const email = data.get('email').toString();
      const password = data.get('password').toString();
      const remember = data.get('remember')?.valueOf() as boolean;
      const auth = initFirebase().auth;
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        console.log(`User ${userCredential.user.email} logged in`)
        if (remember)
            auth.setPersistence({ type: "LOCAL" });
        return json(
          { message: 'Login Successful' },
          { status: 200 }
        );
      } catch (error) {
        console.log('Login Failed');
        console.log(error);
        return json(
          { message: error.message, error: error.code },
          { status: 400 }
        );
      }
    } else throw json(
      { message: 'Invalid Intent' },
      { status: 400 }
    );
  }
}

export default loginRoute;
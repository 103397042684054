import React from "react";

export default function Compose({ contexts, children }) {
  return contexts.reduceRight(
    (acc, Context) =>
      (<Context>
        {acc}
      </Context>),
    children
  );
}
import { redirect } from 'react-router-dom';
import RouteObject from './RouteObject';
import initFirebase from '../data/firebase';
import Logout from '@mui/icons-material/Logout';

const logoutRoute: RouteObject = {
  path: '/logout',
  handle: {
    userMenuItem: {
      title: 'Logout',
      icon: Logout,
      expandChildren: false,
    },
  },
  loader: async () => {
    const auth = initFirebase().auth;
    await auth.authStateReady();
    await auth.signOut();
    return redirect('/');
  }
}

export default logoutRoute;
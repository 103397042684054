import { useParams } from 'react-router-dom';
import RouteObject from './RouteObject';
import makeProtectedLoader from './loaders/protectedLoader';
import InvoiceDetails from '../components/InvoiceDetails';

const payInvoiceRoute : RouteObject = {
  id: 'pay-invoice',
  path: '/invoice/:id',
  loader: makeProtectedLoader(user => !!user, '/login'),
  Component: () => {
    const invoiceId = Number.parseInt(useParams().id);
    document.title = `Invoice #${invoiceId}`;
    return (<InvoiceDetails invoiceId={invoiceId} />)
  }
}

export default payInvoiceRoute;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFirebaseContext } from '../data/context/FirebaseContext';
import { useSettingsContext } from '../data/context/SettingsContext';
import { useInterval, useCounter, useBoolean } from 'usehooks-ts';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import usePeriodicLoader from '../hooks/usePeriodicLoader';


export default function InvoiceTable() {
  const [sourceType, setSourceType] = useState('job');
  const [paymentStatus, setPaymentStatus] = useState('open');
  const navigate = useNavigate();
  const { functions: { getInvoices }} = useFirebaseContext();
  
  const {latestData: invoices, ...invoiceLoader} = usePeriodicLoader(() => {
    return getInvoices({
      sourceType: sourceType,
      status: paymentStatus
    })
  }, 10000, [sourceType, paymentStatus]);

  const handleInvoiceClick = async (invoiceId) => {
    navigate(`/invoice/${invoiceId}`);
  };

  const asDollars = (value) => {
    return Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
  }

  return (
    <div>
      { !invoiceLoader.success && invoiceLoader.latestError && (<Alert severity="error">{invoiceLoader.latestError}</Alert>) }
      { !invoices && (<Alert severity="info">Loading...</Alert>) }
      { invoices && (
        <Table size='small' >
          <TableHead>
            <TableRow>
              <TableCell width='3rem'>ID</TableCell>
              <TableCell width='3rem'>Total</TableCell>
              <TableCell width='3rem'>Remaining</TableCell>
              <TableCell>Customer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices && invoices.map(invoice => (
              <TableRow
                key={invoice.id}
                onClick={() => handleInvoiceClick(invoice.id)}
                hover
                sx={{ cursor: 'pointer' }}
              >
                <TableCell>{invoice.id}</TableCell>
                <TableCell>{asDollars(invoice.total.incTax)}</TableCell>
                <TableCell>{asDollars(invoice.total.incTax - invoice.total.amountApplied)}</TableCell>
                <TableCell>{invoice.customer.givenName && `${invoice.customer.givenName} ${invoice.customer.familyName}` || invoice.customer.companyName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>)
      }
    </div>
  );
}
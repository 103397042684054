import React, { createContext, useContext, useState, useMemo, useEffect } from "react";
import { getAuth, Auth, User } from "firebase/auth";
import { useFirebaseContext } from "./FirebaseContext";

interface AuthState {
  auth: Auth,
  user: User,
}

const AuthContext = createContext<AuthState>(null);

export function AuthContextProvider({children}) {
  const firebase = useFirebaseContext();
  const auth = useMemo<Auth>(() => getAuth(firebase.app), [firebase]);
  const [user, setUser] = useState<User>(auth.currentUser);

  useEffect(() => {
    return auth.onAuthStateChanged(user => {
      setUser(user);
    });
  },
  [auth])

  return (
      <AuthContext.Provider value={{
        auth,
        user,
        }}>
        {children}
      </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  return useContext(AuthContext);
};
import { useNavigate, Link } from 'react-router-dom';
import { useFirebaseContext } from '../data/context/FirebaseContext';
import usePeriodicLoader from '../hooks/usePeriodicLoader';
import { useBoolean } from 'usehooks-ts';
import { Alert, Grid, Typography, Container, Box, Tooltip } from '@mui/material';
import PaymentCard from './PaymentCard';
import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import InvoicePayments from './InvoicePayments';
import InvoicePaymentOptions from './InvoicePaymentOptions';


//todo: handleAction();

 const InvoiceDetails = ({invoiceId}) => {
    const {functions: {getInvoice}} = useFirebaseContext();

    const [outOfSyncMessage, setOutOfSyncMessage] = useState('');

    const {latestData: invoice, ...invoiceLoader} = usePeriodicLoader(() => {
      return getInvoice({ invoiceId });
    }, 10000, [getInvoice, invoiceId]);

    useEffect(() => {
      if (!invoice || !invoice.payments || !invoice.payments.length)
        return;
      const invoicePaid = invoice.total - invoice.remaining;
      const paymentsTotal = invoice.payments.reduce(payment => payment.status === 'complete' ? payment.amount : 0)
      if (invoicePaid === paymentsTotal)
        setOutOfSyncMessage(''); //intentionally blank - in sync.
      else if (invoicePaid < paymentsTotal)
        setOutOfSyncMessage('Some recent payments are yet to be applied')
      else
        setOutOfSyncMessage('Contact EdinAlarm for a full list of payments, as not all are shown here')
    }, [invoice]);

    return (
      <div>
        {(!invoice) && (<Alert severity='info'>Loading...</Alert>)}
        {invoice && (
          <Box sx={{maxWidth: '300rem', minWidth: '70%'}}>
            <Box>
              <Typography variant='h3'>{invoice.customer.name}</Typography>
              <Tooltip open={outOfSyncMessage} placement={'right'} title={outOfSyncMessage}>
                <Typography variant='subtitle1'>
                  Total: ${invoice.total}, Remaining: ${invoice.remaining}
                </Typography>
              </Tooltip>
              
            </Box>
            <Box>
              {/* if any payments are applied, show payment summary and indicate wether payments sum equals paid amount */
                invoice.payments
                && !!invoice.payments.length
                && (<InvoicePayments payments={invoice.payments}/>)
              }
              
              {/* show payment methods if invoice is not fully paid, and paid amount equals payment sum (systems are in sync) */
                (invoice.remaining)
                  ? outOfSyncMessage || (<InvoicePaymentOptions invoiceId={invoiceId} customerId={invoice.customer.id} />)
                  : "Fully paid"
              }
              
            </Box>
          </Box>
        )}
      </div>
    );
  }
  
  export default InvoiceDetails;
import { RouterProvider } from 'react-router-dom';
import './App.css';
import Compose from './components/Compose';
import { AuthContextProvider } from './data/context/AuthContext';
import { FirebaseContextProvider } from './data/context/FirebaseContext';
import { SettingsContextProvider } from './data/context/SettingsContext';
import router from './routes/router';
import { ThemeProvider } from '@emotion/react';
import theme from './data/theme/theme';

function App() {
  

  return (
    <Compose contexts={[
      SettingsContextProvider,
      FirebaseContextProvider,
      AuthContextProvider,
    ]}>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Compose>
  );
}

export default App;

import { FormLabel, ToggleButton, Typography } from "@mui/material";
import { useSettingsContext } from "../data/context/SettingsContext";
import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";



const AutoRefreshToggle = () => {
  const settings = useSettingsContext();

  return (
    settings.showAutoRefreshToggle.value &&
      <FormLabel component={Paper} sx={{ display: "flex", alignItems: "center", borderRadius: "7px", paddingX: '10px'}}>
        <Typography variant="h4" fontSize={16}>Auto Refresh</Typography>
        <Switch checked={settings.autoRefresh.value} onChange={settings.autoRefresh.toggle}/>
      </FormLabel>
  )
}

export default AutoRefreshToggle;
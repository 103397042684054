import React from 'react';
import RouteObject from './RouteObject';
import makeProtectedLoader from './loaders/protectedLoader';
import InvoiceTable from '../components/InvoiceTable';
import Receipt from '@mui/icons-material/Receipt';


const invoiceListRoute : RouteObject = {
  id: 'invoice-list',
  path: '/invoices',
  handle: {
    menuItem: {
      title: 'Invoices',
      icon: Receipt,
      expandChildren: false,
    },
  },
  loader: makeProtectedLoader((user) => !!user, '/login'),
  Component: () => {
    document.title = 'Invoices';
    return <InvoiceTable/>
  }
}

export default invoiceListRoute;

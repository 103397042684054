import {FirebaseOptions, initializeApp as _initializeApp} from 'firebase/app';
import {getFunctions as _getFunctions, httpsCallable, connectFunctionsEmulator} from 'firebase/functions';
import {getFirestore as _getFirestore, connectFirestoreEmulator} from 'firebase/firestore';
import {getAuth as _getAuth, connectAuthEmulator} from 'firebase/auth';


import * as GetInvoices from 'portal-common/types/functions/getInvoices';
import * as GetInvoice from 'portal-common/types/functions/getInvoice';
import * as GetPaymentMethods from 'portal-common/types/functions/getPaymentMethods';
import * as GetPaymentLink from 'portal-common/types/functions/getPaymentLink';
import * as MakePayment from 'portal-common/types/functions/makePayment';

const cache : Record<string, any> = {};

const initFirebase = (appOptions: Partial<FirebaseOptions> = {}, {
    initializeApp = _initializeApp,
    getFunctions = _getFunctions,
    getFirestore = _getFirestore,
    getAuth = _getAuth,
} = {}) => {
    
    appOptions = {
        apiKey: "AIzaSyCtxrsjIRVXEdP8-VZ0BhSF61tjrwAVobs",
        authDomain: "edinalarm-portal.firebaseapp.com",
        databaseURL: "https://edinalarm-portal-default-rtdb.firebaseio.com",
        projectId: "edinalarm-portal",
        storageBucket: "edinalarm-portal.appspot.com",
        messagingSenderId: "453169265978",
        appId: "1:453169265978:web:f6ea264b799d3a0abca403",
        ...appOptions,
      };

    //base64 of appOptions
    const cacheKey = JSON.stringify(appOptions);
    if (cache[cacheKey]) {
        return cache[cacheKey] as never;
    }
    
    const app = initializeApp(appOptions);
    const appFunctions = getFunctions(app);
    const appFirestore = getFirestore(app);
    const auth = getAuth(app);
    
    if (process.env.NODE_ENV === 'development') {
        console.log('development');
        connectFunctionsEmulator(appFunctions, 'localhost', 5001);
        connectFirestoreEmulator(appFirestore, 'localhost', 8080);
        connectAuthEmulator(auth, 'http://localhost:9099');
    } else {
        console.log('production');
    }
    
    const ensureSignedIn = () => {
        if (!auth.currentUser) {
            throw new Error("Not signed in");
        }
    }
    
    
    
    const firebase = {
        app,
        auth,
        functions: {
            appFunctions,
            byName: (name: string) => {
                return httpsCallable(appFunctions, name);
            },
            getInvoices: async (data: GetInvoices.Input) : Promise<GetInvoices.Output> => {
                console.log('getInvoices', 'data', data);
                ensureSignedIn();
                const result = (await httpsCallable<GetInvoices.Input, GetInvoices.Output>(appFunctions, 'getInvoices')(data)).data;
                console.log('getInvoices', 'result', result)
                return result;
            },
            getInvoice: async (data: GetInvoice.Input) : Promise<GetInvoice.Output> => {
                console.log('getInvoice', 'data', data);
                ensureSignedIn();
                const result = (await httpsCallable<GetInvoice.Input, GetInvoice.Output>(appFunctions, 'getInvoice')(data)).data;
                console.log('getInvoice', 'result', result)
                return result;
            },
            getPaymentMethods: async (data: GetPaymentMethods.Input) : Promise<GetPaymentMethods.Output> => {
                console.log('getPaymentMethods', 'data', data);
                ensureSignedIn();
                const result = (await httpsCallable<GetPaymentMethods.Input, GetPaymentMethods.Output>(appFunctions, 'getPaymentMethods')(data)).data;
                console.log('getPaymentMethods', 'result', result)
                return result;
            },
            getPaymentLink: async (data: GetPaymentLink.Input) : Promise<GetPaymentLink.Output> => {
                console.log('getPaymentLink', 'data', data);
                ensureSignedIn();
                const result = (await httpsCallable<GetPaymentLink.Input, GetPaymentLink.Output>(appFunctions, 'getPaymentLink')(data)).data;
                console.log('getPaymentLink', 'result', result)
                return result;
            },
            makePayment: async (data: MakePayment.Input) : Promise<MakePayment.Output> => {
                console.log('makePayment', 'data', data);
                ensureSignedIn();
                const result = (await httpsCallable<MakePayment.Input, MakePayment.Output>(appFunctions, 'makePayment')(data)).data;
                console.log('makePayment', 'result', result)
                return result;
            }
        }
    };

    cache[cacheKey] = firebase;
    return firebase;
}

export default initFirebase;

export type Firebase = ReturnType<typeof initFirebase>;


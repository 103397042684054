import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import makeProtectedLoader from './loaders/protectedLoader';
import RouteObject from './RouteObject';


const paymentCompleteRoute : RouteObject = {
  id: 'payment-complete',
  path: '/payment-complete/:id',
  loader: makeProtectedLoader((user) => !!user, '/login'),
  Component: () => {
    const invoiceId = Number.parseInt(useParams().id);

    useEffect(() => {
      const handleStripeCallback = async () => {
        try {
        } catch (error) {
          console.error(error);
        }
      };

      handleStripeCallback();
    }, [invoiceId]);

    return (
      <div>
        {/* Implement UI for Stripe callback page */}
        <p>Processing payment...</p>
      </div>
    );
  }
}

export default paymentCompleteRoute;
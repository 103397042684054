import { Box, FormControlLabel, Paper, Switch, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';
import { Form, useFetcher } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';


const LoginForm = ({onComplete}) => {
  const showAlert = useBoolean(true);
  const loading = useBoolean(false);
  const loginFetcher = useFetcher({ key: 'login' });
  
  useEffect(() => {
    if ( loginFetcher.data
      && !loginFetcher.data.error
      && onComplete)
      onComplete(loginFetcher.data);
  }, [loginFetcher.data, onComplete]);
  

  return (
    <Box
      sx={{
        maxWidth: '400px',
        padding: '2px',
      }}
      
    >
      <Form method="post" navigate={false} fetcherKey='login'>
        <Typography variant="h5" component="div" sx={{mb: 2}}>
          Sign In
        </Typography>
        {showAlert.value && loginFetcher.data?.error && (
          <Alert
            severity='error'
            onClose={showAlert.setFalse}
            sx={{mb: 2}}
          >
            Incorrect credentials.
          </Alert>
        )}
        <TextField
          name="email"
          type="email"
          placeholder="Email"
          required
          autoFocus
          autoComplete="email"
          sx={{mb: 2, width: '100%'}}
        />
        <TextField
          name="password"
          type="password"
          placeholder="Password"
          autoComplete='current-password'
          required
          sx={{mb: 2, width: '100%'}}
        />
        <FormControlLabel
          name="remember"
          control={<Switch/>}
          sx={{display: 'block', mb: 2}}
          label="Remember me"
        />
        <Button
          name="intent"
          value="loginEmailPassword"
          type="submit"
          variant="contained"
          disabled={loading.value}
        >
        Sign In
        </Button>
      </Form>
    </Box>
  );
}

export default LoginForm;
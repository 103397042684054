import React, { useState } from 'react';
import { Link, useNavigate, matchPath } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useAuthContext } from '../data/context/AuthContext';
import AutoRefreshToggle from './AutoRefreshToggle';
import { useSettingsContext } from '../data/context/SettingsContext';
import { useToggle } from 'usehooks-ts';
import { Drawer, Icon, useMediaQuery } from '@mui/material';
import { startCase } from 'lodash'
import { routes } from '../routes/router';


export default function NavBar() {
  const { auth } = useAuthContext();
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'));

  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const settings = useSettingsContext();
  const [drawerOpen, toggleDrawerOpen] = useToggle(false);
  
  // Logout

  const handleLogout = () => {
    auth.signOut()
        .then(() => 
            auth.currentUser.getIdToken(true))
  }

  const handleUserMenu = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  return (
      <AppBar position="sticky" sx={{ cursor: 'default', marginBottom: '10px' }}>
        <Toolbar>
          <Box display={'flex'} flexDirection={'row'} alignItems={'start'} flexGrow={1} >
            {
              isDesktop ? 
                  <MenuItems noIcons/>
              : <>
                <Typography variant="h1" fontSize={{ xs: 20, sm: 26}} component="div" sx={{ display: { xs: 'none', sm: 'block' }, position: 'absolute', marginTop: '10px', width: '100%' }}>
                  {document.title}
                </Typography>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: { xs: 0, sm: 2 } }}
                  onClick={() => toggleDrawerOpen()}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor='left'
                  open={drawerOpen}
                  onClose={() => toggleDrawerOpen()}
                  sx={{
                    '& .MuiDrawer-paper': {
                      width: 250,
                      paddingTop: '10px',
                      paddingBottom: '10px',
                      paddingRight: '10px',
                      paddingLeft: '10px',
                    },
                  }}
                >
                    <MenuItems onClick={() => toggleDrawerOpen()}/>
                  
                </Drawer>
              </>
            }
          </Box>

          

          <box display={'flex'} flexDirection={'row'} alignItems={'center'} flexGrow={1}>
            {auth?.currentUser && (
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleUserMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={userMenuAnchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(userMenuAnchorEl)}
                  onClose={handleUserMenuClose}
                >
                  {
                    routes[0].children.filter(route => !!route?.handle?.userMenuItem).map(route => (
                      <MenuItem
                        key={route.path}
                        component={Link}
                        to={route.path}
                        onClick={handleUserMenuClose}
                      >
                        {route.handle.userMenuItem.icon && (
                          <Icon sx={{ mr: 1 }}>
                            {React.createElement(route.handle.userMenuItem.icon)}
                          </Icon>
                        )}
                        {route.handle.userMenuItem.title || startCase(route.id)}
                      </MenuItem>
                    ))
                  }
                </Menu>
              </div>
            )}
          </box>
          
        </Toolbar>
      </AppBar>
  );
}

function MenuItems({ onClick, noIcons = false }) {
  return (
    <>
      <Box maxWidth={200}>
        <AutoRefreshToggle />
      </Box>
      { routes[0].children.filter(route => !!route?.handle?.menuItem).map(route => (
        <MenuItem
          key={route.path}
          disabled={!!matchPath(route.path, window.location.pathname)}
          component={Link}
          to={route.path}
          onClick={onClick}
        >
          {!noIcons && route.handle.menuItem.icon && (
            <Icon sx={{ mr: 1 }}>
              {React.createElement(route.handle.menuItem.icon)}
            </Icon>
          )}
          {route.handle.menuItem.title || startCase(route.id)}
        </MenuItem>
      ))}
    </>
  )
}

/*
import React from "react";
import {}
import { Link } from 'react-router-dom';
import { useAuthContext } from "../data/context/AuthContext";

export default function NavBar() {
    const { auth } = useAuthContext();

    function logout() {
        auth.signOut()
            .then(() => 
                auth.currentUser.getIdToken(true))
    }

    return (
        <header className="bg-dark" style={{ paddingLeft: '1rem' }}>
            <NavbarBootstrap className='text-light' >
                <Link to="/" className='nav-link'>Invoices</Link>
                <Link onClick={logout} className='nav-link' style={ {position: 'absolute', right: '1rem'}}>Logout</Link>
            </NavbarBootstrap>
        </header>
    )

}*/
import { useNavigate, Link } from 'react-router-dom';
import { useFirebaseContext } from '../data/context/FirebaseContext';
import usePeriodicLoader from '../hooks/usePeriodicLoader';
import { useBoolean } from 'usehooks-ts';
import { Alert, Grid, Typography, Container, Box } from '@mui/material';
import PaymentCard from '../components/PaymentCard';
import { capitalize } from 'lodash';
import { useCallback } from 'react';


//todo: handleAction();

 const InvoicePaymentOptions = ({invoiceId, customerId}) => {
    const {functions: {getPaymentMethods, getPaymentLink, makePayment}} = useFirebaseContext();
    const waiting = useBoolean(false);

    const {latestData: paymentMethods, ...paymentMethodsLoader} = usePeriodicLoader(() => {
      if (!customerId) return;
      return getPaymentMethods({ customerId });
    }, 10000, [getPaymentMethods, customerId]);


    const callMakePayment = useCallback(async (paymentMethodId) => {
      if (waiting.value) return;
      waiting.setTrue();
      
      try {
        return await makePayment({
          invoiceId,
          paymentMethodId
        });
      } finally {
        waiting.setFalse();
      }
    }, [waiting, invoiceId, makePayment]);

    const callGetPaymentLinkSingle = useCallback(async () => {
      if (waiting.value) return;
      waiting.setTrue();
      
      try {
        const result = await getPaymentLink({
          invoiceId,
          customerId,
          savePaymentMethod: false,
          successUrl: window.location.origin + '/invoice/'+invoiceId,
          cancelUrl: window.location.href
        });
        window.location.href = result.url;
      } finally {
        waiting.setFalse();
      }
    }, [waiting, invoiceId, customerId, getPaymentLink]);

    const callGetPaymentLinkAddCard = useCallback(async () => {
      if (waiting.value) return;
      waiting.setTrue();

      try {
        const result = await getPaymentLink({
          invoiceId,
          customerId,
          savePaymentMethod: true,
          successUrl: window.location.origin + '/invoice/'+invoiceId,
          cancelUrl: window.location.href
        });
        window.location.href = result.url;
      } finally {
        waiting.setFalse();
      }
    }, [waiting, invoiceId, customerId, getPaymentLink]);

    return (
      <>
        {(!paymentMethods) && (<Alert severity='info'>Loading...</Alert>)}
        {paymentMethods && paymentMethods.length > 0 && (
          <Container
            sx={{
              width: {
                xs: '18rem',
                sm: '35rem',
                md: '55rem',
                lg: '75rem',
                xl: '95rem',
              },
              marginTop: '1rem',
            }}
          >
            <Typography variant='h5' sx={{ marginBottom: '2rem' }}>
              Make a Payment
            </Typography>

            <Grid container spacing={3} justifyContent={'left'} paddingBottom={4}>
                {paymentMethods.map(paymentMethod => {
                  return (
                    <Grid item key={paymentMethod.id} lg={3} md={4} sm={6} xs={12} height={'9rem'}>
                      <PaymentCard
                        id={paymentMethod.id}
                        disabled={waiting.value}
                        onClick={() => callMakePayment(paymentMethod.id)}
                        title={capitalize(paymentMethod.brand)}
                        last4={paymentMethod.last4 && `**** **** **** ${paymentMethod.last4}`}
                        expiration={paymentMethod.exp_month && `${paymentMethod.exp_month}/${paymentMethod.exp_year}`}
                        name={paymentMethod.name}
                        buttonText='Pay Now'
                        />
                    </Grid>
                  );
                })}
                <Grid item key='addPaymentCard' lg={3} md={4} sm={6} xs={12} height={'9rem'}>
                  <PaymentCard
                    id='addPaymentCard'
                    disabled={waiting.value}
                    onClick={() => callGetPaymentLinkAddCard()}
                    title='Add a payment method'
                    buttonText='Pay with Stripe'
                    />
                </Grid>
                <Grid item key='singlePaymentCard' lg={3} md={4} sm={6} xs={12} height={'9rem'}>
                  <PaymentCard
                      id='singlePaymentCard'
                      disabled={waiting.value}
                      onClick={() => callGetPaymentLinkSingle()}
                      title='Make a single payment'
                      buttonText='Pay with Stripe'
                      />
                </Grid>
            </Grid>
          </Container>
 )
    }
      </>
    )
        
  }
  
  export default InvoicePaymentOptions;
import React, { createContext, useContext, useEffect } from "react";
import { useBoolean  } from "usehooks-ts";
import useVar from "../../hooks/useVar";


const SettingsContext = createContext<ReturnType<typeof useSettings>>(null);

export function SettingsContextProvider({children}) {

    const settings = useSettings();

    return (
        <SettingsContext.Provider value={settings}>
            {children}
        </SettingsContext.Provider>
    )
}

export function useSettingsContext() {
  return useContext(SettingsContext);
};


function useSettings() {
    const autoRefresh = useBoolean(false);
    const showAutoRefreshToggle = useBoolean(false);

    return {
        autoRefresh,
        showAutoRefreshToggle,
    };
}
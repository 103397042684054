import { LoaderFunction, redirect } from 'react-router-dom';
import initFirebase from '../../data/firebase';
import { User } from 'firebase/auth';


const makeProtectedLoader = (
  authorization: (user : User | null) => boolean,
  redirectTo: string = '/login'
  ) : LoaderFunction =>
  async ({ request }) => {
    // If the user is not logged in and tries to access, we redirect
    // them to `/login` with a `from` parameter that allows login to redirect back
    // to this page upon successful authentication
    const auth = initFirebase().auth;
    await auth.authStateReady();
    if (!authorization(auth.currentUser)) {
      let params = new URLSearchParams();
      params.set("from", new URL(request.url).pathname);
      return redirect(redirectTo + "?" + params.toString());
    }
    return null;
}

export default makeProtectedLoader;
import React, { createContext, useContext, useMemo } from "react";
import initFirebase, { Firebase } from "../firebase";


const FirebaseContext = createContext<Firebase>(null);

export function FirebaseContextProvider({children}) {
  const firebase = useMemo(() => initFirebase(), []);


  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  )
}

export function useFirebaseContext() {
  return useContext(FirebaseContext);
};
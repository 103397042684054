import { Outlet } from "react-router-dom"
import NavBar from "./NavBar"
import React from "react"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid"
import Container from "@mui/material/Container"


const Root = () => {
  return (
    <div className="App">
      <NavBar/>
      
      <Outlet/>
    </div>
  )
}

export default Root;
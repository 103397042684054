import { DependencyList, useEffect, useMemo, useRef, useState } from "react";
import { useSettingsContext } from "../data/context/SettingsContext"
import { useBoolean, useInterval, useIsomorphicLayoutEffect } from "usehooks-ts";

export interface PeriodicLoaderReturn<T> {
  success: boolean;
  loading: boolean;
  latestData: Awaited<T> | null;
  latestError: Error | null;
}

const usePeriodicLoader = <T>(loader: () => T, delay: number | null = null, deps: DependencyList = [], noInstantRetry: boolean = false) : PeriodicLoaderReturn<T> => {
  const { autoRefresh, showAutoRefreshToggle } = useSettingsContext();
  const refreshTrigger = useBoolean();
  const [data, setData] = useState<Awaited<T> | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false);

  const calculatedDelay = useMemo(() =>
    autoRefresh.value
      ? success || noInstantRetry
        ? delay
      : 0
    : null
    ,[autoRefresh.value, success, noInstantRetry, delay])
    

  const loaderRef = useRef(loader)

  // Remember the latest loader if it changes.
  useIsomorphicLayoutEffect(() => {
    loaderRef.current = loader
  }, [loader])


  useEffect(() => {
    showAutoRefreshToggle.setTrue();
    return showAutoRefreshToggle.setFalse;
  }, [showAutoRefreshToggle]);


  useInterval(() => {
    refreshTrigger.toggle();
  }, calculatedDelay);

  useEffect(() => {
    const _setData = (_data: Awaited<T>) => {
      if (_data instanceof Error)
        return setError(_data as Error);
      setData(_data);
      setSuccess(true);
      setloading(false);
    }, _setError = (_error: Error) => {
      setError(_error);
      setSuccess(false);
      setloading(false);
    }

    setloading(true);
    try {
      const _data = loaderRef.current();
      Promise
        .resolve(_data)
        .then(_setData)
        .catch(_setError);
    } catch (error) {
      _setError(error);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTrigger.value, ...deps])

  return {
    latestData: data,
    success,
    loading,
    latestError: error
  };
}

export default usePeriodicLoader;